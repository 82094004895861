import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  gamesCount: 1,
  items: [],
  winItems: null,
  isScrolling: false,
  isPlayed: false,
  isDemo: false,
  isFirstLoad: true
};

export const caseRouletteReducer = createSlice({
  name: '@caseRoulette',
  initialState: INITIAL_STATE,
  reducers: {
    setGamesCount(state, { payload }) {
      state.gamesCount = payload;
    },
    addItems(state, { payload }) {
      payload?.map((item, index) => {
        state.items[index].push(item);
        const randomEnd = state.items[index].slice(2, 6);
        state.items[index] = state.items[index].concat(randomEnd);
      });
    },
    setWinItems(state, { payload }) {
      state.winItems = payload;
    },
    setIsScrolling(state, { payload }) {
      state.isScrolling = payload;
    },
    setIsPlayed(state, { payload }) {
      state.isPlayed = payload;
    },
    setItems(state, { payload }) {
      state.items = payload;
    },
    setIsDemo(state, { payload }) {
      state.isDemo = payload;
    },
    setIsFirstLoad(state, { payload }) {
      state.isFirstLoad = payload;
    }
  }
});

export const {
  setGamesCount,
  addItems,
  setWinItems,
  setIsScrolling,
  setIsPlayed,
  setItems,
  setIsDemo,
  setIsFirstLoad
} = caseRouletteReducer.actions;

export default caseRouletteReducer.reducer;
